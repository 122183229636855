<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="'待处理('+ getFirstAuth.serviceInfo +')'" name="0"></el-tab-pane>
      <el-tab-pane label="历史" name="1"></el-tab-pane>
    </el-tabs>
    <div v-for="(item,i) in serviceInfo" :key="i" class="listbox">
      <el-row :gutter="80">
        <el-col :span="8">
          <table>
            <tr :label="value" v-for="(value,key) in listHead" :key="key">
              <td>{{value}}</td>
              <td>{{item[key]}}</td>
            </tr>
          </table>
        </el-col>
        <el-col :span="16">
          <el-row>
            <el-col :span="12">{{item.content}}</el-col>
            <el-col :span="12">
              <div class="imglist">
                <img
                  :src="img"
                  v-for="(img,index) in item.imgs "
                  :key="index+'a'"
                  width="60px"
                  height="60px"
                  alt
                  @click="previewImg(img)"
                  style="margin: 0 10px"
                />
              </div>
            </el-col>
          </el-row>
          <div v-for="(child,index) in item.children" :key="index+'b'" class="childbox">
            <el-row>
              <el-col :span="12">
                <div
                  :class="child.replyType==1?'green':''"
                >{{child.nickName}} {{child.replyTypeName}}：</div>
                <div>{{child.content}}</div>
              </el-col>
              <el-col :span="12">
                <div class="imglist">
                  <img
                    style="margin: 0 10px"
                    :src="pic"
                    width="60px"
                    alt
                    @click="previewImg(pic)"
                    v-for="(pic,index) in item.children.imgs "
                    :key="index+'c'"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="childbox" v-if="activeName==0">
            <el-button type="primary" size="mini" @click="reply(item)">回复</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-pagination
      :page-size="count"
      :pager-count="9"
      :current-page.sync="page"
      layout="total,prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="img" width="100%" alt />
    </el-dialog>
    <el-dialog :visible.sync="showReply" top="2vh" destroy-on-close title="回复">
      <el-input v-model="params.content" placeholder type="textarea" :autosize="{ minRows: 5 }"></el-input>

      <div style="margin-top:40px">
        <label for>上传图片</label>
        <el-upload
          action="https://server.solar960.com/api/fileUploadWithThumb"
          :headers="headerObj"
          list-type="picture-card"
          multiple
          :limit="3"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div style="margin-top:40px">
        <el-button type="primary" size="mini" @click="submitReplyInfo()">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  adminGetServiceInfo,
  AdminReplyServiceInfo,
  deleteFile
} from "@/api/api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: false,
      showImg: false,
      showReply: false,
      headerObj: {
        Accept: "application/x.server.v1+json",
        Authorization: sessionStorage.getItem("Authorization")
      },
      activeName: "0",
      img: "",
      fileList: [],
      serviceInfo: [],
      listHead: {
        idUser: "提交人",
        phone: "提交人电话",
        nickName: "提交人昵称",
        custType: "类别",
        custId: "客户号",
        custName: "客户名称"
      },
      page: 1,
      total: 0,
      count: 10,
      custType: "",
      params: {
        idUser: this.$store.getters.getAdmin.idUser,
        custId: "",
        content: "",
        imgs: [],
        parrentId: 0,
        replyType: 1,
        submitterId: ""
      }
    };
  },
  mounted() {
    this.getServiceInfoFn();
  },
  computed: {
    ...mapGetters(["getFirstAuth"])
  },
  methods: {
    getServiceInfoFn() {
      const that = this;
      adminGetServiceInfo({
        page: that.page,
        count: that.count,
        state: that.activeName
      }).then(res => {
        that.serviceInfo = res.data.info;
        that.total = res.data.total;
        if (that.activeName == 0) {
          that.getFirstAuth.serviceInfo = that.total;
          that.$store.dispatch("setFirstAuth", that.getFirstAuth);
        }
      });
    },
    handleClick() {
      this.getServiceInfoFn();
    },

    reply(item) {
      this.params.custType = item.custType;
      this.params.custId = item.custId;
      this.params.content = "";
      this.params.parrentId = item.id;
      this.params.submitterId = item.idUser;
      this.showReply = true;
    },

    handleRemove(res) {
      let file = res.response.data.files;
      deleteFile({
        file: file
      }).then(res => {
        console.log(res);
      });
      let index = this.params.imgs.findIndex(item => {
        return item == file;
      });
      this.params.imgs.splice(index, 1);
    },

    handleAvatarSuccess(res) {
      let imgUrl = res.data.filePath;
      this.params.imgs.push(imgUrl);
    },
    beforeAvatarUpload(file) {
      //限制格式
      const isJPG =
        (file.type === "image/jpg") |
        (file.type === "image/png") |
        (file.type === "image/jpeg");
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、JPEG 或 PNG 格式!");
      }
      return isJPG;
    },
    submitReplyInfo() {
      const that = this;
      if (!that.params.content) {
        this.$message.warning(`内容不能为空`);
      } else {
        AdminReplyServiceInfo(that.params).then(res => {
          if (res.result) {
            this.$message.success(`提交成功`);
            this.showReply = false;
            this.getServiceInfoFn();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getServiceInfoFn();
    },
    previewImg(item) {
      this.showImg = true;
      this.img = item.replace(".thumb", "");
    }
  }
};
</script>

<style>
.listbox {
  margin: 20px;
  padding: 20px;
  background: #fff;
  border: 1px solid #999;
}
.imglist {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.childbox {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #bbb;
  line-height: 28px;
}
.green {
  color: mediumseagreen;
}
</style>